import { logError, logWarn } from '@domains/shared/helpers/logger';
import { checkIsUnauthorizedError } from '@lib/graphql/checkIsUnauthorizedError';
import { NETWORK_ERRORS_TO_IGNORE } from '@lib/graphql/consts/networkErrorsToIgnore';
import type { ExecutableDefinitionNode } from 'graphql';
import { useEffect } from 'react';
import type { CombinedError, Operation } from 'urql';

/**
 * Hook to handle client-side GraphQL errors
 * @param descriptor
 * Function name or a component name. Allows to identify the source of the error in the logs.
 * @param error
 * GraphQL error object, extracted from `useQuery` or `useMutation` result. Allows to identify whether the error is a network error or a GraphQL error, and to log the error details.
 * @param operation
 * GraphQL operation object, extracted from `useQuery` or `useMutation` result. Allows to identify the exact query or mutation that caused the error in the logs.
 * @param extraData
 * Extra data to log with the error
 * @param shouldLogUnauthorizedError
 * Allows to skip logging of unauthorized network errors.
 * @example with operation
 * const [{ data, error, operation }] = useQuery();
 * useHandleGraphqlError({ descriptor: 'useAccountAdsQuery', error, operation });
 * @example with no operation
 * const [{ data, error }] = useQuery();
 * useHandleGraphqlError({ descriptor: 'AccountAds', error });
 */
export const useHandleGraphqlError = ({
    error,
    descriptor,
    operation,
    extraData,
    shouldLogUnauthorizedError = true,
}: {
    error: CombinedError | undefined;
    descriptor: string;
    operation?: Operation;
    extraData?: Record<string, unknown>;
    shouldLogUnauthorizedError?: boolean;
}): void => {
    const operationDefinition = operation?.query.definitions[0] as ExecutableDefinitionNode | undefined;
    const operationLabel = operationDefinition?.name?.value;

    useEffect(() => {
        if (!error) {
            return;
        }

        const operationPrefix = operationLabel ? `${operationLabel}@` : '';
        const logPrefix = `[${operationPrefix}${descriptor}] `;

        const { graphQLErrors, networkError } = error;
        if (networkError) {
            if (NETWORK_ERRORS_TO_IGNORE.has(networkError.message.toLowerCase())) {
                // use warn to keep the track of the issue but do not report it as an error to Sentry
                logWarn(`${logPrefix}Network error, ignored`, { error: networkError });

                return;
            }
            if (shouldLogUnauthorizedError || !checkIsUnauthorizedError(error)) {
                logError(`${logPrefix}Network error`, { error: networkError });
            }

            return;
        }
        const shouldSkipLogging =
            !shouldLogUnauthorizedError &&
            graphQLErrors?.length > 0 &&
            !graphQLErrors.some(({ message }) => !message.includes('Forbidden Access'));
        if (shouldSkipLogging) {
            // Skip logging unauthorized error

            return;
        }

        logError(`${logPrefix}GraphQL error`, { error, extraData });
    }, [descriptor, error, extraData, operationLabel, shouldLogUnauthorizedError]);
};
