import type { NavUserMenuLink } from '@config/siteConfig';
import { SITE_CONFIG } from '@config/siteConfig';
import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { localiseRoute } from '@lib/routes/localiseRoute';
import type { JSX } from 'react';
import { useContext } from 'react';

import { NexusRWDContext } from '../../contexts/NexusRWDContext';
import { UserMenuItem } from '../NavUserMenu/components/UserMenuItem/UserMenuItem';
import { getUserMenuLinks } from '../NavUserMenu/helpers/getUserMenuLinks';
import { useNavUserMenu } from '../NavUserMenu/hooks/useNavUserMenu';
import { NexusMenuIconWithNotifications } from '../NexusMenuIconWithNotifications/NexusMenuIconWithNotifications';
import { NavUserAccountMobileMenu } from './NexusNavUserAccountMobile/NavUserAccountMobileMenu';
import { NavUserFavouriteMobileMenu } from './NexusNavUserFavouriteMobile/NavUserFavouriteMobileMenu';
import {
    AccountNexusList,
    NexusChevronDownIcon,
    NexusEmailLabel,
    NexusFavouritesLabel,
    NexusIconWrapper,
    NexusLinkLabel,
    NexusLinkWithIcon,
    NexusList,
    NexusListItem,
    NexusLogoutButton,
    NexusLogoutButtonWrapper,
    NexusLogoutIcon,
    NexusSeparator,
    NexusStaticLink,
    NexusUnreadNotifications,
    NexusUserDefaultLoadingIcon,
    NexusUserIcon,
    NexusUserLabel,
} from './NexusNavUserMenu.theme';

const getNavLinkHref = (navLink: NavUserMenuLink, shouldUseLocalisedHref?: boolean): string => {
    if (shouldUseLocalisedHref && 'localisedHref' in navLink) {
        return navLink.localisedHref;
    }

    if ('href' in navLink) {
        return navLink.href;
    }

    return navLink.localisedHref;
};

const { navUserMenu: LINKS } = SITE_CONFIG;

export const NexusNavUserMenu = (): JSX.Element => {
    const {
        t,
        trackEvent,
        hasErrorOccurred,
        isAwaitingUserData,
        savedAdsCount,
        savedSearchesCount,
        unreadMessagesCount,
        logout,
        favouritesLinks,
        isConfirmedUserLoggedIn,
        loginPageHref,
        isUserLoggedIn,
        user,
        isUnconfirmed,
        isBusinessMember,
        isBusinessAdmin,
        isSubAccount,
        isSettingsOurTeamTabEnabled,
    } = useNavUserMenu({ links: LINKS });
    const { isDesktop } = useContext(NexusRWDContext);
    const { lang } = useSiteSettings();

    const isConfirmedUserLoggedInOnDesktop = isConfirmedUserLoggedIn && isDesktop;
    const showNavUserAccountOnDesktop = isDesktop && user?.email;

    const myAccountLink = localiseRoute(`/${lang}/myaccount`);

    return (
        <>
            {!isUserLoggedIn && (
                <LocalLink passHref href={loginPageHref} prefetch={false}>
                    <NexusLinkWithIcon
                        isExpanded={false}
                        isLoggedIn={false}
                        onClick={(): void =>
                            trackEvent('account_click', {
                                touch_point_button: 'header',
                            })
                        }
                        data-cy="navbar-my-account-button"
                    >
                        {isAwaitingUserData ? (
                            <Icon icon={faSpinner} spin />
                        ) : (
                            <>
                                <NexusUserDefaultLoadingIcon />
                                <NexusLinkLabel isExpanded={false}>
                                    {hasErrorOccurred
                                        ? t('frontend.navbar.menu.loading-error')
                                        : t('frontend.navbar.menu.my-account')}
                                </NexusLinkLabel>
                            </>
                        )}
                    </NexusLinkWithIcon>
                </LocalLink>
            )}

            {isConfirmedUserLoggedInOnDesktop ? (
                <UserMenuItem
                    renderLabel={({ isExpanded, setIsExpanded }): JSX.Element => (
                        <LocalLink href={getNavLinkHref(favouritesLinks[0])} passHref>
                            <NexusLinkWithIcon
                                isExpanded={isExpanded}
                                isLoggedIn={true}
                                onClick={(event): void => {
                                    event.preventDefault();
                                    setIsExpanded((state) => !state);
                                    trackEvent('my_observed_ads_click', { touch_point_button: 'expanded_menu' });
                                }}
                                aria-label={t('frontend.navbar.menu.users-favourite-adverts')}
                            >
                                <NexusMenuIconWithNotifications
                                    counter={savedAdsCount + savedSearchesCount}
                                    isExpanded={isExpanded}
                                />
                            </NexusLinkWithIcon>
                        </LocalLink>
                    )}
                    renderContent={({ setIsExpanded }): JSX.Element => (
                        <NexusList role="menu">
                            <NexusFavouritesLabel>{t('frontend.navbar.menu.favourites')}</NexusFavouritesLabel>
                            <NexusSeparator />

                            {favouritesLinks.map((navLink) => {
                                const { label } = navLink;
                                const href = getNavLinkHref(navLink);
                                const notificationsCount =
                                    label === 'frontend.navbar.menu.favourite-adverts'
                                        ? savedAdsCount
                                        : savedSearchesCount;
                                const shouldShowCounter = notificationsCount;

                                return (
                                    <NexusListItem key={label}>
                                        <LocalLink href={href} passHref>
                                            <NexusStaticLink role="menuitem" onClick={(): void => setIsExpanded(false)}>
                                                {t(label)}
                                                {shouldShowCounter ? (
                                                    <NexusUnreadNotifications notificationsCount={notificationsCount}>
                                                        {notificationsCount}
                                                    </NexusUnreadNotifications>
                                                ) : null}
                                            </NexusStaticLink>
                                        </LocalLink>
                                    </NexusListItem>
                                );
                            })}
                        </NexusList>
                    )}
                />
            ) : (
                <NavUserFavouriteMobileMenu
                    favouritesLinks={favouritesLinks}
                    getNavLinkHref={getNavLinkHref}
                    isConfirmedUserLoggedIn={isConfirmedUserLoggedIn}
                />
            )}

            {showNavUserAccountOnDesktop ? (
                <UserMenuItem
                    ariaLabel={t('frontend.shared.nav-user-menu.label')}
                    renderLabel={({ isExpanded, setIsExpanded }): JSX.Element => (
                        <NexusLinkWithIcon
                            href={myAccountLink}
                            isExpanded={isExpanded}
                            isLoggedIn={true}
                            data-testid="menu-button-wrapper"
                            data-cy="nav-user-menu.username"
                            onClick={(event): void => {
                                event.preventDefault();
                                trackEvent('account_click', {
                                    touch_point_button: 'header',
                                });
                                setIsExpanded((state) => !state);
                            }}
                        >
                            {unreadMessagesCount ? (
                                <NexusMenuIconWithNotifications
                                    counter={unreadMessagesCount}
                                    shouldHideLabel
                                    showUserIcon
                                    isExpanded={isExpanded}
                                />
                            ) : (
                                <NexusIconWrapper>
                                    <NexusUserDefaultLoadingIcon />
                                    <NexusChevronDownIcon isActive={isExpanded} />
                                </NexusIconWrapper>
                            )}
                        </NexusLinkWithIcon>
                    )}
                    renderContent={({ setIsExpanded }): JSX.Element => (
                        <AccountNexusList role="menu">
                            <NexusUserLabel>
                                <NexusUserIcon />
                                <NexusEmailLabel>{user?.email}</NexusEmailLabel>
                            </NexusUserLabel>
                            <NexusSeparator />
                            {getUserMenuLinks({
                                userType: user.userType,
                                isUnconfirmed,
                                isBusinessMember,
                                isBusinessAdmin,
                                isSubAccount,
                                isSettingsOurTeamTabEnabled,
                            }).map((menuLink) => {
                                const { label, tracking } = menuLink;

                                const href = getNavLinkHref(menuLink);

                                const shouldShowUnreadNotificaiton =
                                    unreadMessagesCount > 0 && label === 'frontend.navbar.menu.answers';

                                const navLink = (
                                    <NexusStaticLink
                                        role="menuitem"
                                        href={href}
                                        onClick={(): void => {
                                            if (tracking) {
                                                trackEvent(tracking.eventName, {
                                                    touch_point_button: tracking.touchPointButton,
                                                });
                                            }

                                            setIsExpanded(false);
                                        }}
                                    >
                                        {t(label)}
                                        {shouldShowUnreadNotificaiton ? (
                                            <NexusUnreadNotifications notificationsCount={unreadMessagesCount}>
                                                {unreadMessagesCount}
                                            </NexusUnreadNotifications>
                                        ) : null}
                                    </NexusStaticLink>
                                );

                                if ('localisedHref' in menuLink) {
                                    return (
                                        <NexusListItem key={label}>
                                            <LocalLink passHref href={href} prefetch={false}>
                                                {navLink}
                                            </LocalLink>
                                        </NexusListItem>
                                    );
                                }

                                return <NexusListItem key={label}>{navLink}</NexusListItem>;
                            })}
                            <NexusSeparator />
                            <NexusLogoutButtonWrapper>
                                <NexusLogoutIcon />
                                <NexusLogoutButton
                                    role="menuitem"
                                    type="button"
                                    data-cy="nav-user-menu.log-out"
                                    onClick={async (): Promise<void> => {
                                        trackEvent('account_click', {
                                            touch_point_button: 'header_logout',
                                        });
                                        setIsExpanded(false);

                                        await logout?.();
                                    }}
                                >
                                    {t('frontend.navbar.menu.log-out')}
                                </NexusLogoutButton>
                            </NexusLogoutButtonWrapper>
                        </AccountNexusList>
                    )}
                />
            ) : (
                <NavUserAccountMobileMenu
                    myAccountLink={myAccountLink}
                    unreadMessagesCount={unreadMessagesCount}
                    userEmail={user?.email}
                    userType={user?.userType}
                    isUnconfirmed={isUnconfirmed}
                    isBusinessMember={isBusinessMember}
                    isBusinessAdmin={isBusinessAdmin}
                    isSubAccount={isSubAccount}
                    getNavLinkHref={getNavLinkHref}
                    isSettingsOurTeamTabEnabled={isSettingsOurTeamTabEnabled}
                />
            )}
        </>
    );
};
