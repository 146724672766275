import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Container, Row } from '@nexus/lib-react/dist/core/Layout';

interface NavigationContainerProps {
    isVisible: boolean;
}
export const NexusNavigationContainer = styled(Container)<NavigationContainerProps>`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    transition: opacity 0.2s;
    opacity: ${({ isVisible }): number => (isVisible ? 1 : 0)};
`;

export const NexusMenuContainer = styled(Container)`
    position: relative;
    width: 100%;
    margin-right: auto;

    @media ${theme.mediaQueries.xlMin} {
        display: flex;
        position: static;
        justify-content: center;
        width: 100%;
    }
`;

export const NexusDesktopVisibleContainer = styled(Row)`
    display: none;

    @media ${theme.mediaQueries.xlMin} {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: ${theme.space['12']} 0 ${theme.space['12']} ${theme.space['8']};
    }
`;

export const NexusNavUserMenuWrapper = styled(NexusDesktopVisibleContainer)`
    @media ${theme.mediaQueries.xlMin} {
        justify-content: flex-end;
        min-width: 130px;
        padding: 0;
    }
`;
