import { useAnimateNavbarIcon } from '@domains/shared/hooks/useAnimateNavbarIcon/useAnimateNavbarIcon';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';
import { useContext } from 'react';

import { NexusRWDContext } from '../../contexts/NexusRWDContext';
import {
    Container,
    NexusChevronDownIcon,
    NexusHeartIcon,
    NexusLabel,
    NexusUsertIcon,
} from './NexusMenuIconWithNotifications.theme';

interface Props {
    counter: number;
    shouldHideLabel?: boolean;
    isInMainMenu?: boolean;
    shouldHideChevron?: boolean;
    showUserIcon?: boolean;
    isExpanded?: boolean;
}

export const NexusMenuIconWithNotifications = ({
    counter,
    shouldHideLabel,
    isInMainMenu,
    shouldHideChevron,
    showUserIcon,
    isExpanded,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const shouldAnimateIcon = useAnimateNavbarIcon();

    const { isDesktop } = useContext(NexusRWDContext);

    const shouldShowLabel = !shouldHideLabel && !isDesktop;
    const shouldShowCounter = counter > 0;

    const handleClick = (): void => {
        if (isInMainMenu) {
            trackEvent('my_observed_ads_click', { touch_point_button: 'header' });
        }
    };

    return (
        <Container
            shouldShowCounter={shouldShowCounter}
            counter={counter}
            shouldAddRightSpacing={isInMainMenu && !isDesktop}
            data-testid="menu-icon-with-notifications"
            onClick={handleClick}
            shouldAnimateBadge={shouldAnimateIcon}
            shouldShowLabel={shouldShowLabel}
        >
            {showUserIcon ? (
                <NexusUsertIcon data-testid="menu-user-icon" />
            ) : (
                <NexusHeartIcon data-testid="menu-icon" />
            )}
            {shouldHideChevron ? null : <NexusChevronDownIcon isActive={!!isExpanded} />}
            {shouldShowLabel ? <NexusLabel>{t('frontend.nav-user-menu.users-favourite-label')}</NexusLabel> : null}
        </Container>
    );
};
