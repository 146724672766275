import type { NavUserMenuLink } from '@config/type';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { UserType } from '@type/user/userType';
import { NexusMobileMenuContext } from '@widgets/header/MainHeader/contexts/NexusMobileMenuContext';
import type { JSX } from 'react';
import { memo, useCallback, useContext } from 'react';

import {
    NexusChevronLeftIcon,
    NexusChevronRightIcon,
    SubMenuWrapper,
} from '../../Menu/components/NexusMobileMenu/NexusMobileMenu.theme';
import { MenuIconWithNotifications } from '../../MenuIconWithNotifications/MenuIconWithNotifications';
import { getUserMenuLinks } from '../../NavUserMenu/helpers/getUserMenuLinks';
import { MobileLinkWithIcon } from '../NexusNavUserFavouriteMobile/NavUserFavouriteMobileMenu.theme';
import { NexusUserDefaultLoadingIcon } from '../NexusNavUserMenu.theme';
import { HeaderWrapper, MobileMenuWrapper, NexusUserEmail } from './NavUserAccountMobileMenu.theme';
import { UserAccountLinkList } from './UserAccountLinkList';

interface Props {
    myAccountLink: string;
    unreadMessagesCount: number;
    userEmail?: string;
    userType?: UserType;
    isUnconfirmed: boolean;
    isBusinessMember?: boolean;
    isBusinessAdmin?: boolean;
    isSubAccount: boolean;
    isSettingsOurTeamTabEnabled?: boolean | null;
    getNavLinkHref: (navLink: NavUserMenuLink, shouldUseLocalisedHref?: boolean) => string;
}

const NavUserAccountMobileMenuBase = ({
    myAccountLink,
    unreadMessagesCount,
    userEmail,
    userType,
    isUnconfirmed,
    getNavLinkHref,
    isBusinessMember,
    isBusinessAdmin,
    isSubAccount,
    isSettingsOurTeamTabEnabled,
}: Props): JSX.Element | null => {
    const { setShowAccountItems, shouldShowAccountItems, shouldShowFavouritesItems } =
        useContext(NexusMobileMenuContext);
    const [t] = useTranslations();
    const { trackEvent } = useTracking();

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLSpanElement, MouseEvent>): void => {
            event.preventDefault();
            setShowAccountItems(true);
            trackEvent('account_click', {
                touch_point_button: 'header',
            });
        },
        [setShowAccountItems, trackEvent],
    );

    const handleBackClick = useCallback((): void => {
        setShowAccountItems(false);
    }, [setShowAccountItems]);

    const linkList = userType
        ? getUserMenuLinks({
              userType,
              isUnconfirmed,
              isBusinessMember,
              isBusinessAdmin,
              isSubAccount,
              isSettingsOurTeamTabEnabled,
          })
        : [];

    if (!userEmail || shouldShowFavouritesItems) {
        return null;
    }

    return (
        <MobileMenuWrapper data-testid="nav-user-account" role="navigation">
            {shouldShowAccountItems ? (
                <>
                    <NexusChevronLeftIcon onClick={handleBackClick} />
                    <SubMenuWrapper>
                        <UserAccountLinkList
                            unreadMessagesCount={unreadMessagesCount}
                            linkList={linkList}
                            getNavLinkHref={getNavLinkHref}
                            level={0}
                        />
                    </SubMenuWrapper>
                </>
            ) : (
                <HeaderWrapper
                    onClick={(event): void => handleClick(event)}
                    data-testid="nav-user-account-header"
                    aria-label="nav-user-account-header"
                >
                    <MobileLinkWithIcon href={myAccountLink} isLoggedIn={true}>
                        {unreadMessagesCount ? (
                            <MenuIconWithNotifications counter={unreadMessagesCount} shouldHideLabel />
                        ) : (
                            <NexusUserDefaultLoadingIcon />
                        )}

                        <NexusUserEmail
                            aria-label={t('frontend.navbar.menu.username')}
                            role="region"
                            data-cy="nav-user-menu.username"
                        >
                            {userEmail}
                        </NexusUserEmail>
                    </MobileLinkWithIcon>
                    <NexusChevronRightIcon />
                </HeaderWrapper>
            )}
        </MobileMenuWrapper>
    );
};

export const NavUserAccountMobileMenu = memo(NavUserAccountMobileMenuBase);
