import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useAnimateNavbarIcon } from '@domains/shared/hooks/useAnimateNavbarIcon/useAnimateNavbarIcon';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHeart } from '@fortawesome/free-regular-svg-icons/faHeart';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';
import { useContext } from 'react';

import { Container, Label } from './MenuIconWithNotifications.theme';

interface Props {
    counter: number;
    shouldHideLabel?: boolean;
    isInMainMenu?: boolean;
    icon?: IconProp;
}

export const MenuIconWithNotifications = ({
    counter,
    shouldHideLabel,
    isInMainMenu,
    icon = faHeart,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const shouldAnimateIcon = useAnimateNavbarIcon();

    const { isDesktop, isTablet } = useContext(RWDContext);
    const isMobile = !isDesktop && !isTablet;

    const shouldShowLabel = !shouldHideLabel && isMobile;
    const shouldShowCounter = counter > 0;
    const shouldUseBigIcon = isInMainMenu && (isMobile || isTablet);

    const handleClick = (): void => {
        if (isInMainMenu) {
            trackEvent('my_observed_ads_click', { touch_point_button: 'header' });
        }
    };

    return (
        <Container
            shouldShowCounter={shouldShowCounter}
            counter={counter}
            shouldAddRightSpacing={isInMainMenu && isMobile}
            data-testid="menu-icon-with-notifications"
            onClick={handleClick}
            shouldAnimateBadge={shouldAnimateIcon}
        >
            <Icon icon={icon} transform={{ size: shouldUseBigIcon ? 24 : 16 }} data-testid="menu-icon" />
            {shouldShowLabel ? <Label>{t('frontend.nav-user-menu.users-favourite-label')}</Label> : null}
        </Container>
    );
};
