import type { NavUserMenuLink } from '@config/type';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useLazyUser } from '@lib/pages/contexts/LazyUserContext/useLazyUser';
import { useTracking } from '@lib/tracking/useTracking';
import { LinkList } from '@widgets/PageFooter/components/LinksSection/LinksSection.theme';
import type { JSX } from 'react';
import { memo } from 'react';

import { StyledLinkItem } from '../../Menu/components/NexusMobileMenu/NexusMobileMenu.theme';
import { NexusLogoutIcon, NexusUnreadNotifications } from '../NexusNavUserMenu.theme';
import { MobileLogoutButton, MobileLogOutItem } from './NavUserAccountMobileMenu.theme';

interface Props {
    unreadMessagesCount: number;
    linkList: NavUserMenuLink[];
    level?: number;
    getNavLinkHref: (navLink: NavUserMenuLink, shouldUseLocalisedHref?: boolean) => string;
}

const UserAccountLinkListBase = ({
    unreadMessagesCount,
    linkList,
    getNavLinkHref,
    level = 1,
}: Props): JSX.Element | null => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const { logout } = useLazyUser();

    return (
        <LinkList role="menu" aria-label="user-account-mobile-menu" data-testid="user-account-mobile-menu">
            {linkList.map((menuLink) => {
                const { label, tracking } = menuLink;

                const href = getNavLinkHref(menuLink);

                const shouldShowUnreadNotificaiton =
                    unreadMessagesCount > 0 && label === 'frontend.navbar.menu.answers';

                const navLink = (
                    <StyledLinkItem
                        data-testid="user-account-mobile-menuitem"
                        role="menuitem"
                        aria-label={label}
                        href={href}
                        level={level}
                        onClick={(): void => {
                            if (tracking) {
                                trackEvent(tracking.eventName, {
                                    touch_point_button: tracking.touchPointButton,
                                });
                            }
                        }}
                    >
                        {t(label)}
                        {shouldShowUnreadNotificaiton ? (
                            <NexusUnreadNotifications
                                notificationsCount={unreadMessagesCount}
                                data-testid="nav-user-account-notification"
                                aria-label="nav-user-account-notification"
                            >
                                {unreadMessagesCount}
                            </NexusUnreadNotifications>
                        ) : null}
                    </StyledLinkItem>
                );

                if ('localisedHref' in menuLink) {
                    return (
                        <li key={label}>
                            <LocalLink passHref href={href} prefetch={false}>
                                {navLink}
                            </LocalLink>
                        </li>
                    );
                }

                return <li key={label}>{navLink}</li>;
            })}
            <MobileLogOutItem level={level}>
                <NexusLogoutIcon />
                <MobileLogoutButton
                    role="menuitem"
                    aria-label={t('frontend.navbar.menu.log-out')}
                    data-testid="user-account-mobile-menuitem"
                    type="button"
                    data-cy="nav-user-menu.log-out"
                    onClick={async (): Promise<void> => {
                        trackEvent('account_click', {
                            touch_point_button: 'header_logout',
                        });

                        await logout?.();
                    }}
                >
                    {t('frontend.navbar.menu.log-out')}
                </MobileLogoutButton>
            </MobileLogOutItem>
        </LinkList>
    );
};

export const UserAccountLinkList = memo(UserAccountLinkListBase);
