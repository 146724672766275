import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { AccordionContent } from '@nexus/lib-react/dist/core/Accordion';
import ChevronLeft from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronLeft';
import ChevronRight from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronRight';
import { HEADER_WIDGET_THEME } from '@widgets/header/theme/[[[SITECODE]]]';

export const MobileMenuWrapper = styled.li`
    display: block;
    margin: 0;
    margin-top: ${theme.space['48']};
    padding: 0;
    background-color: transparent;
    list-style: none;

    @media ${theme.mediaQueries.xlMin} {
        position: absolute;
        z-index: ${HEADER_WIDGET_THEME.layer.x6};
        left: 0;
        padding: ${theme.space['12']} ${theme.space['16']};
        background-color: ${theme.colors['background-global-primary']};
        box-shadow: ${theme.colors['background-brand-primary']};
    }

    &[aria-labelledby='aria-frontend.navbar.menu.companies'] {
        @media ${theme.mediaQueries.xlMin} {
            @supports (break-inside: avoid-column) {
                column-fill: auto;
            }
        }
    }

    li {
        @supports (break-inside: avoid-column) {
            break-inside: avoid-column;
        }
    }

    [id='li-frontend.navbar.menu.primary-market-frontend.navbar.menu.primary-market.see-ads'] {
        column-span: all;
    }
`;

export const MenuList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;
export const NexusChevronLeftIcon = styled(ChevronLeft)`
    position: absolute;
    top: ${theme.space['22']};
    width: ${theme.sizes['32']};
    height: ${theme.sizes['32']};
    color: ${theme.colors['background-global-inverse']};
    cursor: pointer;
`;

export const SubMenuWrapper = styled(AccordionContent)`
    padding-top: 0;
    svg {
        color: ${theme.colors['icon-global-primary']};
    }
`;

const calculatePaddingLeft = (level?: number): string => {
    if (level && level === 1) {
        return theme.space['16'];
    } else if (level === 2) {
        return theme.space['32'];
    } else {
        return '0';
    }
};

export const StyledLinkItem = styled.a<{ level?: number; showChevron?: boolean }>`
    display: ${({ showChevron }): string => (showChevron ? 'flex' : 'block')};
    justify-content: space-between;
    width: 100%;
    height: auto;
    margin: 0;
    padding: ${theme.space['16']} 0 ${theme.space['16']} ${({ level }): string => calculatePaddingLeft(level)};
    border-bottom: ${theme.borderWidths[1]} ${theme.borderStyles.solid} ${theme.colors['borders-global-tertiary']};
    color: ${theme.colors['text-global-primary']};
    font-size: ${theme.typography.p2.fontSize};
    font-weight: ${theme.fontWeights[400]};
    line-height: ${theme.sizes['22']};
    text-decoration: none;
    cursor: pointer;
`;

export const NexusChevronRightIcon = styled(ChevronRight)`
    width: ${theme.sizes['24']};
    height: ${theme.sizes['24']};
    color: ${theme.colors['background-global-inverse']};
`;
