import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

import { HEADER_WIDGET_THEME } from '../../../theme/[[[SITECODE]]]';

interface ContainerProps {
    counter: number;
    shouldShowCounter?: boolean;
    shouldAddRightSpacing?: boolean;
    shouldAnimateBadge: boolean;
}

export const Container = styled.div<ContainerProps>`
    position: relative;
    align-self: center;
    margin-right: ${({ shouldAddRightSpacing }): string => (shouldAddRightSpacing ? '72px' : '0')};
    margin-left: ${({ shouldShowCounter }): string => (shouldShowCounter ? theme.space['8'] : '0')};
    transform: scale(${({ shouldAnimateBadge }): string => (shouldAnimateBadge ? '1.25' : '1')});
    transition: transform 0.3s ease-in-out;

    &::before {
        content: '${({ counter }): number => counter}';
        display: ${({ shouldShowCounter }): string => (shouldShowCounter ? 'block' : 'none')};
        position: absolute;
        top: -8px;
        left: -8px;
        min-width: ${theme.space['16']};
        padding: ${theme.space['0']} ${theme.space['2']};
        border-radius: 16px;
        background-color: ${HEADER_WIDGET_THEME.navUserMenu.notifications.backgroundColor};
        color: ${theme.colors['text-global-inverse']};
        font-size: ${theme.typography.p5.fontSize};
        font-weight: ${theme.fontWeights['700']};
        line-height: ${theme.typography.p5.lineHeight};
        text-align: center;
    }
`;

export const Label = styled.span`
    padding: ${theme.space['8']} ${theme.space['14']};
    font-weight: ${theme.fontWeights['700']};
`;
