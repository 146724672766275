import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import ChevronDown from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronDown';
import Logout from '@nexus/lib-react/dist/core/Icon/icons/default/Logout';
import UserDefault from '@nexus/lib-react/dist/core/Icon/icons/default/UserDefault';

import { HEADER_WIDGET_THEME } from '../../../theme/[[[SITECODE]]]';

interface PropsWithExpanded {
    isExpanded: boolean;
}

export const NexusLinkWithIcon = styled.a<PropsWithExpanded & { isLoggedIn: boolean }>`
    display: flex;
    position: relative;
    z-index: ${HEADER_WIDGET_THEME.layer.x2};
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    border-bottom: ${theme.borderWidths[1]} ${theme.borderStyles.solid} ${theme.colors['borders-global-tertiary']};
    color: ${theme.colors['text-brand-primary']};
    font-size: ${theme.typography.p2.fontSize};
    line-height: ${theme.typography.p2.lineHeight};
    text-decoration: none;

    @media ${theme.mediaQueries.xlMin} {
        max-width: 140px;
        border: none;
        font-size: ${theme.typography.p3.fontSize};
    }
`;

export const NexusLinkLabel = styled.span<PropsWithExpanded>`
    display: block;
    position: relative;
    max-width: 180px;
    padding-top: ${theme.space['16']};
    padding-bottom: ${theme.space['16']};
    padding-left: ${theme.space['8']};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media ${theme.mediaQueries.lgMin} {
        display: block;
    }
`;

export const NexusList = styled.ul`
    min-width: 190px;
    margin: 0;
    padding: 0 ${theme.space['12']} ${theme.space['16']};
    font-size: ${theme.typography.p2.fontSize};
    list-style: none;

    @media ${theme.mediaQueries.lgMin} {
        position: absolute;
        z-index: ${HEADER_WIDGET_THEME.layer.x1};
        top: 60px;
        right: 0;
        left: 0;
        padding: ${theme.space['16']};
        border: ${theme.borderWidths[1]} ${theme.borderStyles.solid} ${theme.colors['borders-global-tertiary']};
        border-radius: 0 0 ${theme.radii[4]} ${theme.radii[4]};
        background-color: ${theme.colors['background-global-primary']};
        color: ${theme.colors['text-brand-primary']};
        font-size: ${theme.typography.p3.fontSize};
    }

    @media ${theme.mediaQueries.xlMin} {
        top: 72px;
    }
`;

export const NexusListItem = styled.li`
    padding: 0;
    white-space: nowrap;
    &:not(:first-of-type) {
        margin: ${theme.space['24']} 0 0;
    }
`;

export const NexusStaticLink = styled.a`
    display: block;
    transition: color 0.25s ease-in-out;
    color: ${theme.colors['text-brand-primary']};
    text-decoration: none;

    @media ${theme.mediaQueries.lgMin} {
        color: ${theme.colors['text-brand-primary']};

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
        &:focus {
            text-decoration: underline;
        }
    }
`;

export const NexusLogoutButtonWrapper = styled.li`
    display: flex;
    gap: ${theme.space['8']};
    align-items: center;
`;

export const NexusLogoutButton = styled.a`
    margin: 0;
    transition: color 0.25s ease-in-out;
    border: none;
    outline: none;
    background: none;
    font-size: ${theme.typography.p2.fontSize};

    @media ${theme.mediaQueries.xlMin} {
        font-size: ${theme.typography.p3.fontSize};
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

        &:focus {
            text-decoration: underline;
        }
    }
`;

interface PropsWithNotifications {
    notificationsCount: number;
}

export const NexusUnreadNotifications = styled.span<PropsWithNotifications>`
    display: inline-block;
    min-width: ${theme.space['20']};
    margin-left: ${theme.space['8']};
    border-radius: ${theme.radii[12]};
    background-color: ${theme.colors['background-global-inverse']};
    color: ${theme.colors['text-global-inverse']};
    font-size: ${theme.typography.p5.fontSize};
    font-weight: ${theme.fontWeights['700']};
    line-height: ${theme.space['20']};
    text-align: center;
    vertical-align: top;
`;

export const NexusFavouritesLabel = styled.div`
    font-weight: ${theme.fontWeights['400']};
`;

export const NexusUserLabel = styled.p`
    display: flex;
    align-items: center;
    gap: 4px;
    margin: auto;
`;

export const NexusSeparator = styled.hr`
    height: 1px;
    margin: ${theme.space['16']} 0;
    border: none;
    background-color: ${theme.colors['borders-global-tertiary']};
`;

export const NexusUserDefaultLoadingIcon = styled(UserDefault)`
    color: ${theme.colors['background-global-inverse']};
`;

export const NexusChevronDownIcon = styled(ChevronDown)<{ isActive: boolean }>`
    width: ${theme.space['16']};
    height: ${theme.space['16']};
    transform: ${({ isActive }): string => (isActive ? 'rotate(180deg)' : 'rotate(0)')};
    transition: transform 0.25s ease-in-out;
    color: ${theme.colors['background-global-inverse']};
`;

export const NexusLogoutIcon = styled(Logout)`
    color: ${theme.colors['background-global-inverse']};
`;

export const NexusIconWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${theme.space['8']};
`;

export const NexusUserIcon = styled(UserDefault)`
    color: ${theme.colors['background-global-inverse']};
`;

export const NexusEmailLabel = styled.span`
    width: 300px;
    overflow: hidden;
    font-weight: ${theme.fontWeights['400']};
    text-overflow: ellipsis;
`;

export const AccountNexusList = styled(NexusList)`
    min-width: 300px;
`;
