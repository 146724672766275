import { useShouldPauseQueryForLogoutUser } from '@domains/shared/hooks/useShouldPauseQueryForLogoutUser/useShouldPauseQueryForLogoutUser';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import type { UseAssertGraphQlPropsGeneric } from '@lib/graphql/hooks/useAssertGraphqlResponse';
import { useAssertGraphqlResponse } from '@lib/graphql/hooks/useAssertGraphqlResponse';
import { normalizeForbiddenAccessQuery } from '@lib/graphql/normalizeForbiddenAccessQuery';
import { USER_INFO_QUERY_CONTEXT } from '@lib/pages/contexts/LazyUserContext/const/userInfoQueryContext';
import { useQuery } from 'urql';

import { GET_USER_INFO_QUERY } from '../graphql/queries/UserInfoQuery';

const EXPECTED_USER_INFO_DATA = ['UserInfo'] as const;
const EXPECTED_SAVED_SEARCH_DATA = ['SearchCount'] as const;
const EXPECTED_OBSERVED_ADS_TYPENAME = ['FavouriteAdvertsList'] as const;

type PartialUseAssertGraphQlPropsGeneric = Pick<
    UseAssertGraphQlPropsGeneric,
    'fetching' | 'graphqlError' | 'logErrorPrefix' | 'onTypeMismatch' | 'operation' | 'onUserError' | 'pause'
>;

export const useFavouritesCounter = (): {
    savedAdsCount: number;
    savedSearchesCount: number;
    unreadMessagesCount: number;
    isUserDataAvailable: boolean;
} => {
    const {
        featureFlags: { isObservedAdsPageEnabled },
    } = useSiteSettings();
    const shouldPauseQuery = useShouldPauseQueryForLogoutUser();
    const [
        { data: userInfoData, error: userInfoGraphQlError, fetching: isFetchingUserInfo, operation: userInfoOperation },
    ] = useQuery({
        query: GET_USER_INFO_QUERY,
        context: USER_INFO_QUERY_CONTEXT,
        // Do not execute the query when a user is logged out. It is especially important when there is executed the SPA log in action,
        // because there is returned a cached "error" instead of the query re-execution based on typename invalidation
        pause: shouldPauseQuery,
        variables: {
            isObservedAdsPageEnabled: !!isObservedAdsPageEnabled,
        },
    });
    const commonUserInfoErrorHandlingData: PartialUseAssertGraphQlPropsGeneric = {
        fetching: isFetchingUserInfo,
        graphqlError: userInfoGraphQlError,
        onTypeMismatch: 'DO_NOTHING',
        onUserError: 'CHECK_AND_DO_NOTHING',
        operation: userInfoOperation,
        pause: shouldPauseQuery,
    } as const;
    const userInfo = useAssertGraphqlResponse({
        ...commonUserInfoErrorHandlingData,
        data: normalizeForbiddenAccessQuery(userInfoData?.userInfo, userInfoGraphQlError),
        expectedTypenames: EXPECTED_USER_INFO_DATA,
        pause: shouldPauseQuery,
    });
    const savedSearch = useAssertGraphqlResponse({
        ...commonUserInfoErrorHandlingData,
        data: normalizeForbiddenAccessQuery(userInfoData?.savedSearch, userInfoGraphQlError),
        expectedTypenames: EXPECTED_SAVED_SEARCH_DATA,
        pause: shouldPauseQuery,
    });
    const observedAds = useAssertGraphqlResponse({
        ...commonUserInfoErrorHandlingData,
        data: normalizeForbiddenAccessQuery(userInfoData?.observedAds, userInfoGraphQlError),
        expectedTypenames: EXPECTED_OBSERVED_ADS_TYPENAME,
        pause: shouldPauseQuery,
    });
    const savedAdsCount = (isObservedAdsPageEnabled ? observedAds?.totalCount : userInfo?.observedAds?.total) || 0;
    const savedSearchesCount = savedSearch ? savedSearch?.count : userInfo?.observedSearches?.total;
    const unreadMessagesCount = userInfo?.unreadMessages?.total || 0;

    return {
        isUserDataAvailable: !!userInfo,
        savedAdsCount,
        savedSearchesCount: savedSearchesCount || 0,
        unreadMessagesCount,
    };
};
