import { GET_USER_ACTIVE_FEATURES_QUERY } from '@domains/shared/graphql/queries/GetUserActiveFeatures';
import { useHandleGraphqlError } from '@lib/graphql/hooks/useHandleGraphqlError';
import type { UserActiveFeature } from '@type/user/userActiveFeature';
import { useQuery } from 'urql';

interface UserActiveFeatures {
    activeFeatures: UserActiveFeature[];
}

export interface ReturnValue {
    userActiveFeatures: UserActiveFeature[];
    isFetchingActiveFeatures: boolean;
}

export const useUserActiveFeatures = (userId?: number): ReturnValue => {
    const [{ data: userFeatures, error, operation, fetching }] = useQuery<UserActiveFeatures>({
        query: GET_USER_ACTIVE_FEATURES_QUERY,
        pause: !userId,
        variables: {
            userId,
        },
    });

    useHandleGraphqlError({ descriptor: 'UserActiveFeatures', error, operation });

    return { userActiveFeatures: userFeatures?.activeFeatures ?? [], isFetchingActiveFeatures: fetching };
};
