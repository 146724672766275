import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { HEADER_WIDGET_THEME } from '@widgets/header/theme/[[[SITECODE]]]';

export const Container = styled.div`
    position: relative;
    z-index: ${HEADER_WIDGET_THEME.layer.x0};
    height: 100%;

    @media ${theme.mediaQueries.lgMin} {
        z-index: ${HEADER_WIDGET_THEME.layer.x2};
        padding: 0;
        border: ${theme.borderStyles.none};
    }
`;
