import type { NavUserMenuLink } from '@config/siteConfig';
import { SITE_CONFIG } from '@config/siteConfig';
import type { UserType } from '@type/user/userType';
import { USER_TYPE } from '@type/user/userType';

export const getUserMenuLinks = ({
    userType,
    isUnconfirmed,
    isBusinessMember = false,
    isBusinessAdmin = false,
    isSubAccount = false,
    isSettingsOurTeamTabEnabled = false,
}: {
    userType: UserType;
    isUnconfirmed: boolean;
    isBusinessMember?: boolean;
    isBusinessAdmin?: boolean;
    isSubAccount: boolean;
    isSettingsOurTeamTabEnabled?: boolean | null;
}): NavUserMenuLink[] => {
    const { navUserMenu: links } = SITE_CONFIG;

    if (isUnconfirmed) {
        return links.unconfirmedUser;
    }

    if (isBusinessAdmin) {
        return isSettingsOurTeamTabEnabled
            ? links.businessAdmin
            : links.businessAdmin.filter((link) => link.label !== 'frontend.navbar.menu.our-team');
    }

    if (isBusinessMember) {
        return links.businessMember;
    }

    if (isSubAccount) {
        return links.businessUser.filter((link) => link.label !== 'frontend.navbar.menu.wallet');
    }

    if (userType === USER_TYPE.agency || userType === USER_TYPE.developer) {
        return links.businessUser;
    }

    return links.regularUser;
};
