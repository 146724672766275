import type { CombinedError } from 'urql';

export const checkIsUnauthorizedError = (error: CombinedError | undefined): boolean => {
    // Registered user
    if (error?.networkError?.message === 'Unauthorized') {
        return true;
    }

    // Unconfirmed user
    if (error?.graphQLErrors.some((error) => error.message.includes('UnauthorizedException'))) {
        return true;
    }

    return false;
};
