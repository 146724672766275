import { AD_SAVED_EVENT_NAME } from '@domains/shared/consts/adSavedEventName';
import { listenForPlatformEvent, unmountPlatformEvent } from '@lib/events';
import { useEffect, useRef, useState } from 'react';

export const useAnimateNavbarIcon = (): boolean => {
    const timeoutId = useRef<NodeJS.Timeout | null>(null);
    const [shouldAnimateIcon, setShouldAnimateIcon] = useState<boolean>(false);

    useEffect(() => {
        const listener = (): void => {
            setShouldAnimateIcon(true);
            timeoutId.current = setTimeout(() => {
                setShouldAnimateIcon(false);
            }, 300);
        };
        listenForPlatformEvent(AD_SAVED_EVENT_NAME, listener);

        return (): void => {
            unmountPlatformEvent(AD_SAVED_EVENT_NAME, listener);
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }
        };
    }, []);

    return shouldAnimateIcon;
};
