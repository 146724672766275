import { ROUTE_SEGMENT } from '@config/routes/routesConfig';
import type { NavUserMenu, NavUserMenuLink } from '@config/type';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useUserActiveFeatures } from '@domains/shared/hooks/useUserFeatures/useUserActiveFeatures';
import type { Locale } from '@lib/i18n/types/locale';
import { useLazyUser } from '@lib/pages/contexts/LazyUserContext/useLazyUser';
import type { TrackEvent } from '@lib/tracking/types';
import { useTracking } from '@lib/tracking/useTracking';
import type { LazyUser } from '@type/user/lazyUser';
import { USER_ACTIVE_FEATURE } from '@type/user/userActiveFeature';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useFavouritesCounter } from '../helpers/useFavouritesCounter';

interface UseNavUserMenuInput {
    links: NavUserMenu;
}

interface UseNavUserMenuResult {
    t: Translator;
    trackEvent: TrackEvent;
    lang: Locale;
    hasErrorOccurred: boolean;
    isAwaitingUserData: boolean;
    savedAdsCount: number;
    savedSearchesCount: number;
    unreadMessagesCount: number;
    logout?: () => Promise<void>;
    favouritesLinks: NavUserMenuLink[];
    isConfirmedUserLoggedIn?: boolean;
    loginPageHref: string;
    isUserLoggedIn?: boolean;
    user?: LazyUser | null;
    isUnconfirmed: boolean;
    isBusinessMember: boolean;
    isBusinessAdmin: boolean;
    isSubAccount: boolean;
    isSettingsOurTeamTabEnabled: boolean | null;
}

export const useNavUserMenu = ({ links }: UseNavUserMenuInput): UseNavUserMenuResult => {
    const router = useRouter();
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const {
        lang,
        featureFlags: { isSettingsOurTeamTabEnabled },
    } = useSiteSettings();
    const { hasErrorOccurred, isAwaitingUserData, isUserLoggedIn, user, logout } = useLazyUser();
    const { savedAdsCount, savedSearchesCount, unreadMessagesCount } = useFavouritesCounter();
    const isUnconfirmed = Boolean(user?.isUnconfirmed);
    const favouritesLinks = links.favourites;
    const isConfirmedUserLoggedIn = isUserLoggedIn && !isUnconfirmed;
    const [loginPageHref, setLoginPageHref] = useState('/[lang]/login');

    const { userActiveFeatures } = useUserActiveFeatures(user?.id);
    const isBusinessMember = !!userActiveFeatures.includes(USER_ACTIVE_FEATURE.businessMember);
    const isBusinessAdmin = !!userActiveFeatures.includes(USER_ACTIVE_FEATURE.businessAdmin);
    const isSubAccount = Boolean(user?.isSubAccount);

    useEffect(() => {
        const loginPage = ROUTE_SEGMENT.login;
        if (new RegExp(`^/[a-z]{2}/${loginPage}($|\\?)`).test(router?.asPath)) {
            // Reset - do not redirect login page to login page
            setLoginPageHref('/[lang]/login');

            return;
        }
        setLoginPageHref(`/[lang]/login?redirect_path=${encodeURIComponent(router?.asPath)}`);
    }, [setLoginPageHref, router?.asPath]);

    return {
        t,
        trackEvent,
        lang,
        hasErrorOccurred,
        isAwaitingUserData,
        savedAdsCount,
        savedSearchesCount,
        unreadMessagesCount,
        logout,
        favouritesLinks,
        isConfirmedUserLoggedIn,
        loginPageHref,
        isUserLoggedIn,
        user,
        isUnconfirmed,
        isBusinessMember,
        isBusinessAdmin,
        isSubAccount,
        isSettingsOurTeamTabEnabled,
    };
};
