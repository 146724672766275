import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import ChevronDown from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronDown';
import HeartDefault from '@nexus/lib-react/dist/core/Icon/icons/default/HeartDefault';
import UserDefault from '@nexus/lib-react/dist/core/Icon/icons/default/UserDefault';

interface ContainerProps {
    counter: number;
    shouldShowCounter?: boolean;
    shouldAddRightSpacing?: boolean;
    shouldAnimateBadge: boolean;
    shouldShowLabel?: boolean;
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    position: relative;
    align-items: center;
    align-self: center;
    margin-right: ${({ shouldAddRightSpacing }): string => (shouldAddRightSpacing ? '72px' : '0')};
    margin-left: ${({ shouldShowCounter }): string => (shouldShowCounter ? theme.space['8'] : '0')};
    transform: scale(${({ shouldAnimateBadge }): string => (shouldAnimateBadge ? '1.25' : '1')});
    transition: transform 0.3s ease-in-out;
    gap: ${theme.space['8']};

    &::before {
        content: '${({ counter }): number => counter}';
        display: ${({ shouldShowCounter }): string => (shouldShowCounter ? 'block' : 'none')};
        position: absolute;
        top: ${({ shouldShowLabel }): string => (shouldShowLabel ? `-${theme.space['4']}` : `-${theme.space['8']}`)};
        left: -${theme.space['12']};
        min-width: ${theme.space['20']};
        padding: ${theme.space['0']} ${theme.space['2']};
        border-radius: 16px;
        background-color: ${theme.colors['background-global-inverse']};
        color: ${theme.colors['text-global-inverse']};
        font-size: ${theme.typography.p5.fontSize};
        font-weight: ${theme.fontWeights['700']};
        line-height: ${theme.lineHeights['20']};
        text-align: center;
    }
`;

export const NexusLabel = styled.span`
    padding: ${theme.space['8']} 0;
`;

export const NexusHeartIcon = styled(HeartDefault)`
    color: ${theme.colors['background-global-inverse']};
`;

export const NexusUsertIcon = styled(UserDefault)`
    color: ${theme.colors['background-global-inverse']};
`;

export const NexusChevronDownIcon = styled(ChevronDown)<{ isActive: boolean }>`
    width: ${theme.space['16']};
    height: ${theme.space['16']};
    transform: ${({ isActive }): string => (isActive ? 'rotate(180deg)' : 'rotate(0)')};
    transition: transform 0.25s ease-in-out;
    color: ${theme.colors['background-global-inverse']};
`;
