import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Container } from '@nexus/lib-react/dist/core/Layout';
import Link from 'next/link';
import type { CSSProperties } from 'react';

import { HEADER_WIDGET_THEME } from '../../../theme/[[[SITECODE]]]';

interface WrapperProps {
    hasAdOverHeader: boolean;
    isSticky?: boolean;
    backgroundImage?: string;
}

const getWrapperPosition = ({ hasAdOverHeader, isSticky = true }: WrapperProps): CSSProperties['position'] => {
    if (hasAdOverHeader) return 'relative';
    if (isSticky) return 'fixed';

    return 'absolute';
};

export const Wrapper = styled.div<WrapperProps>`
    display: flex;
    position: ${getWrapperPosition};
    z-index: ${HEADER_WIDGET_THEME.layer.x6};
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    border-bottom: ${theme.borderWidths[1]} ${theme.borderStyles.solid} ${theme.colors['borders-global-tertiary']};
    background-color: ${theme.colors['background-global-primary']};
    color: ${theme.colors['text-brand-primary']};

    @media ${theme.mediaQueries.lgMin} {
        padding-top: ${({ hasAdOverHeader }): string => (hasAdOverHeader ? '200px' : '0')};
        background-image: ${({ hasAdOverHeader, backgroundImage }): string =>
            hasAdOverHeader && backgroundImage ? `url(${backgroundImage})` : 'none'};
        background-repeat: no-repeat;
        background-position: center top;
    }
`;

export const Logo = styled(Link)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 153px;
    height: 72px;

    & > img {
        margin-top: -5px;
    }
`;

export const HeaderContainer = styled(Container)`
    display: flex;
    flex-wrap: unset;
    align-items: center;
    justify-content: flex-start;
    padding: 0 ${theme.space['4']} 0 ${theme.space['24']};

    @media ${theme.mediaQueries.xlMin} {
        padding: 0 ${theme.space['24']};
    }
`;
