import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { HEADER_WIDGET_THEME } from '@widgets/header/theme/[[[SITECODE]]]';

export const MobileLogOutItem = styled.li<{ level?: number }>`
    padding-left: ${({ level }): string => (level && level === 1 ? `${theme.space['16']}` : '0')};
    border-bottom: ${theme.borderWidths[1]} ${theme.borderStyles.solid} ${theme.colors['borders-global-tertiary']};
    cursor: pointer;
    svg {
        vertical-align: middle;
    }
`;
export const MobileLogoutButton = styled.button`
    margin: 0;
    padding: ${theme.space['16']};
    padding: ${theme.space['16']} 0 ${theme.space['16']} ${theme.space['8']};
    border: none;
    outline: none;
    background: none;
    color: ${theme.colors['text-global-primary']};
    font-size: ${theme.typography.p2.fontSize};
    cursor: pointer;
`;

export const NexusUserEmail = styled.span`
    width: 210px;
    margin-left: ${theme.space['8']};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media ${theme.mediaQueries.xlMin} {
        display: inline;
    }
`;
export const MobileMenuWrapper = styled.ul`
    display: block;
    margin: 0;
    padding: 0;
    background-color: transparent;
    list-style: none;

    @media ${theme.mediaQueries.xlMin} {
        position: absolute;
        z-index: ${HEADER_WIDGET_THEME.layer.x6};
        left: 0;
        padding: ${theme.space['12']} ${theme.space['16']};
        background-color: ${theme.colors['background-global-primary']};
        box-shadow: ${theme.colors['background-brand-primary']};
    }

    li {
        @supports (break-inside: avoid-column) {
            break-inside: avoid-column;
        }
    }
`;

export const HeaderWrapper = styled.span`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    border-bottom: ${theme.borderWidths[1]} ${theme.borderStyles.solid} ${theme.colors['borders-global-tertiary']};
`;
