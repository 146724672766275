import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { HEADER_WIDGET_THEME } from '@widgets/header/theme/[[[SITECODE]]]';
import type { CSSProperties } from 'react';

export const MobileLinkWithIcon = styled.a<{ isLoggedIn: boolean }>`
    display: flex;
    position: relative;
    z-index: ${HEADER_WIDGET_THEME.layer.x2};
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: ${theme.space['16']} 0;
    background-color: ${theme.colors['background-global-primary']};
    color: ${theme.colors['text-global-primary']};
    font-size: ${theme.typography.p2.fontSize};
    line-height: ${theme.typography.p2.lineHeight};
    text-decoration: none;
    &::after {
        content: '';
        display: ${({ isLoggedIn }): CSSProperties['display'] => (isLoggedIn ? 'inline-block' : 'none')};
        margin-left: ${theme.space['4']};
    }

    @media ${theme.mediaQueries.xlMin} {
        justify-content: flex-end;
        padding: 0 ${theme.space['8']};
        transition:
            background-color 0.25s ease-in-out,
            color 0.25s ease-in-out;
        background-color: ${theme.colors['background-global-primary']};
        font-size: ${theme.typography.p3.fontSize};
    }
`;

export const HeaderWrapper = styled.span`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    border-bottom: ${theme.borderWidths[1]} ${theme.borderStyles.solid} ${theme.colors['borders-global-tertiary']};
`;
