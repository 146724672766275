import type { MenuLink, PageHeaderMenuLink } from '@config/siteConfig';
import { SITE_CONFIG } from '@config/siteConfig';
import type { LangSwitcherLocale } from '@config/type';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTracking } from '@lib/tracking/useTracking';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const useNavigation = (): {
    navbar?: PageHeaderMenuLink[];
    locales: LangSwitcherLocale[];
    isVisible: boolean;
    deprecatedNavbar?: MenuLink[];
    activeLocale: LangSwitcherLocale;
    onClickOnAddItemButton: () => void;
} => {
    const { trackEvent } = useTracking();
    const { lang } = useSiteSettings();
    const [isVisible, setIsVisible] = useState(false);
    const { deprecatedNavbar, locales, navbar } = SITE_CONFIG;
    const activeLocale: LangSwitcherLocale = useMemo(
        () => locales.find((item) => item.code === lang) || locales[0],
        [lang, locales],
    );

    const onClickOnAddItemButton = useCallback(() => {
        trackEvent('posting_form_click', {
            touch_point_button: 'header',
        });
    }, [trackEvent]);

    // By default navigation is transparent, change it's opacity so it appears gracefully
    useEffect(() => {
        // delay is needed to make nice opacity transition
        const timeoutId = setTimeout(() => {
            if (!navbar) {
                setIsVisible(true);
            }
        }, 200);

        return (): void => {
            clearTimeout(timeoutId);
        };
    }, [navbar]);

    return {
        navbar,
        locales,
        isVisible,
        deprecatedNavbar,
        activeLocale,
        onClickOnAddItemButton,
    };
};
