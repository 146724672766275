import type { UserType as TypeUserType } from '@type/user/userType';

/** @deprecated Please use a direct import from '@type/user/userType' */
export type UserType = TypeUserType;

export interface CurrentUser {
    id: number | undefined;
    name: string;
    email: string;
    userType: TypeUserType;
    isSubAccount: boolean;
    isSuspended: boolean;
    isMonetizationMigrated: boolean;
    isUnconfirmed: boolean;
    isUnconfirmedBusinessUser: boolean;
}

/** @deprecated Please use a direct import from '@type/user/userType' */
export { USER_TYPE } from '@type/user/userType';
