import { useClickOutside } from '@domains/shared/hooks/useClickOutside/useClickOutside';
import type { Dispatch, JSX, SetStateAction } from 'react';
import { useRef, useState } from 'react';

import { Container } from './UserMenuItem.theme';

interface RenderFunctionProps {
    isExpanded: boolean;
    setIsExpanded: Dispatch<SetStateAction<boolean>>;
}

interface Props {
    ariaLabel?: string;
    renderContent: ({ isExpanded, setIsExpanded }: RenderFunctionProps) => JSX.Element;
    renderLabel: ({ isExpanded, setIsExpanded }: RenderFunctionProps) => JSX.Element;
}

export const UserMenuItem = ({ ariaLabel = '', renderContent, renderLabel }: Props): JSX.Element => {
    const itemRef = useRef<HTMLDivElement>(null);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    useClickOutside(itemRef, () => {
        setIsExpanded(false);
    });

    return (
        <Container ref={itemRef} role="navigation" aria-label={ariaLabel}>
            {renderLabel({ isExpanded, setIsExpanded })}
            {isExpanded ? renderContent({ isExpanded, setIsExpanded }) : null}
        </Container>
    );
};
