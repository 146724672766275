import type { AbstractGraphQLError } from '@lib/graphql/error';
import { gql } from '@urql/core';

interface UserInfo {
    userInfo:
        | AbstractGraphQLError
        | {
              __typename: 'UserInfo';
              observedAds: {
                  total: number;
              };
              observedSearches: {
                  total: number;
              };
              unreadMessages: {
                  total: number;
              };
          };
    savedSearch:
        | AbstractGraphQLError
        | {
              __typename: 'SearchCount';
              count: number;
          };
    observedAds:
        | AbstractGraphQLError
        | {
              __typename: 'FavouriteAdvertsList';
              totalCount: number;
          };
}

/** Please note, either it looks like, this query has not been yet normalized in GQL error handling */
export const GET_USER_INFO_QUERY = gql<UserInfo>`
    query GetUserInfo($isObservedAdsPageEnabled: Boolean!) {
        userInfo {
            __typename
            observedAds {
                total
            }
            observedSearches {
                total
            }
            unreadMessages {
                total
            }
        }
        savedSearch: getSearchCount {
            __typename
            count
        }
        observedAds: favouriteAdvertsGetAll(withAdvertData: false) @include(if: $isObservedAdsPageEnabled) {
            __typename
            ... on FavouriteAdvertsList {
                totalCount
            }
        }
    }
`;
