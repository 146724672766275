import { SITE_CONFIG } from '@config/siteConfig';
import { useBaxterAdvertising } from '@domains/shared/components/Advertising/useBaxterAdvertising';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';
import { useCallback } from 'react';

import { NexusRWDContextProvider } from '../../contexts/NexusRWDContext';
import { NexusNavigation } from '../NexusNavigation/NexusNavigation';
import { HeaderContainer, Logo, Wrapper } from './NexusHeader.theme';

interface Props {
    isSticky?: boolean;
    investmentId?: string;
    adUnitOverHeader?: string;
}

export const NexusHeader = ({ isSticky, investmentId, adUnitOverHeader }: Props): JSX.Element => {
    const { lang } = useSiteSettings();
    const [t] = useTranslations();
    const { staticAssetsPrefix } = getPublicEnvConfig();
    useBaxterAdvertising();
    const { trackEvent } = useTracking();

    const logo = {
        href: `/${lang === SITE_CONFIG.metadata.defaultLanguage ? '' : lang}`,
        src: `${staticAssetsPrefix}${SITE_CONFIG.images.nexusLogo}`,
    };

    const trackLogoClick = useCallback(() => {
        trackEvent('logo_click', {
            touch_point_button: 'header',
        });
    }, [trackEvent]);

    return (
        <Wrapper hasAdOverHeader={!!adUnitOverHeader} isSticky={isSticky}>
            <NexusRWDContextProvider>
                <HeaderContainer>
                    <Logo
                        href={logo.href}
                        aria-label={t('frontend.shared.page-header.logo-description')}
                        onClick={trackLogoClick}
                    >
                        <img src={logo.src} height={24} alt={t('frontend.shared.page-header.logo-description')} />
                    </Logo>
                    <NexusNavigation investmentId={investmentId} adUnitOverHeader={!!adUnitOverHeader} />
                </HeaderContainer>
            </NexusRWDContextProvider>
        </Wrapper>
    );
};

export default NexusHeader;
