import type { FC, PropsWithChildren } from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';

interface NexusMobileMenuContextProps {
    shouldShowNavUserMenu: boolean;
    shouldShowAddButton: boolean;
    isNavUserMenuOpen: boolean;
    shouldShowFavouritesItems: boolean;
    shouldShowAccountItems: boolean;
    setShouldShowNavUserMenu: (value: boolean) => void;
    setShouldShowAddButton: (value: boolean) => void;
    setIsNavUserMenuOpen: (value: boolean) => void;
    setShowFavouritesItems: (value: boolean) => void;
    setShowAccountItems: (value: boolean) => void;
}

export const NexusMobileMenuContext = createContext<NexusMobileMenuContextProps>({} as NexusMobileMenuContextProps);

export const NexusMobileMenuProvider: FC<PropsWithChildren> = ({ children }) => {
    const [shouldShowNavUserMenu, setShouldShowNavUserMenu] = useState(true);
    const [shouldShowAddButton, setShouldShowAddButton] = useState(true);
    const [isNavUserMenuOpen, setIsNavUserMenuOpen] = useState(false);
    const [shouldShowFavouritesItems, setShowFavouritesItems] = useState(false);
    const [shouldShowAccountItems, setShowAccountItems] = useState(false);

    useEffect(() => {
        const isUserMenuOpen = shouldShowAccountItems || shouldShowFavouritesItems;
        setIsNavUserMenuOpen(isUserMenuOpen);

        if (isUserMenuOpen) {
            setShouldShowAddButton(false);
            if (shouldShowAccountItems) {
                setShowFavouritesItems(false);
            } else {
                setShowAccountItems(false);
            }
        } else {
            setShouldShowAddButton(true);
        }
    }, [setIsNavUserMenuOpen, shouldShowAccountItems, shouldShowFavouritesItems]);

    const contextValue = useMemo(
        () => ({
            shouldShowNavUserMenu,
            setShouldShowNavUserMenu,
            shouldShowAddButton,
            setShouldShowAddButton,
            isNavUserMenuOpen,
            setIsNavUserMenuOpen,
            shouldShowFavouritesItems,
            setShowFavouritesItems,
            shouldShowAccountItems,
            setShowAccountItems,
        }),
        [
            isNavUserMenuOpen,
            shouldShowAccountItems,
            shouldShowAddButton,
            shouldShowFavouritesItems,
            shouldShowNavUserMenu,
        ],
    );

    return <NexusMobileMenuContext.Provider value={contextValue}>{children}</NexusMobileMenuContext.Provider>;
};
