import type { NavUserMenuLink } from '@config/type';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { NexusMobileMenuContext } from '@widgets/header/MainHeader/contexts/NexusMobileMenuContext';
import { LinkList } from '@widgets/PageFooter/components/LinksSection/LinksSection.theme';
import type { JSX } from 'react';
import { memo, useCallback, useContext } from 'react';

import {
    NexusChevronLeftIcon,
    NexusChevronRightIcon,
    StyledLinkItem,
    SubMenuWrapper,
} from '../../Menu/components/NexusMobileMenu/NexusMobileMenu.theme';
import { useFavouritesCounter } from '../../NavUserMenu/helpers/useFavouritesCounter';
import { NexusMenuIconWithNotifications } from '../../NexusMenuIconWithNotifications/NexusMenuIconWithNotifications';
import { MobileMenuWrapper } from '../NexusNavUserAccountMobile/NavUserAccountMobileMenu.theme';
import { NexusUnreadNotifications } from '../NexusNavUserMenu.theme';
import { HeaderWrapper, MobileLinkWithIcon } from './NavUserFavouriteMobileMenu.theme';

interface Props {
    favouritesLinks: NavUserMenuLink[];
    isConfirmedUserLoggedIn?: boolean;
    getNavLinkHref: (navLink: NavUserMenuLink, shouldUseLocalisedHref?: boolean) => string;
}

const NavUserFavouriteMobileMenuBase = ({
    favouritesLinks,
    isConfirmedUserLoggedIn,
    getNavLinkHref,
}: Props): JSX.Element | null => {
    const { setShowFavouritesItems, shouldShowFavouritesItems, shouldShowAccountItems } =
        useContext(NexusMobileMenuContext);
    const [t] = useTranslations();
    const { lang } = useSiteSettings();
    const { trackEvent } = useTracking();
    const { savedAdsCount, savedSearchesCount } = useFavouritesCounter();

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLSpanElement, MouseEvent>): void => {
            event.preventDefault();
            setShowFavouritesItems(true);
            trackEvent('my_observed_ads_click', { touch_point_button: 'expanded_menu' });
        },
        [setShowFavouritesItems, trackEvent],
    );

    const handleBackClick = useCallback((): void => {
        setShowFavouritesItems(false);
    }, [setShowFavouritesItems]);

    if (!isConfirmedUserLoggedIn || shouldShowAccountItems) {
        return null;
    }

    return (
        <MobileMenuWrapper data-testid="nav-user-favourite" aria-label="nav-user-favourite" role="menu">
            {shouldShowFavouritesItems ? (
                <>
                    <NexusChevronLeftIcon onClick={handleBackClick} />
                    <SubMenuWrapper>
                        <LinkList role="menu">
                            {favouritesLinks.map((navLink) => {
                                const { label } = navLink;
                                const href = getNavLinkHref(navLink).replace('[lang]', lang);
                                const notificationsCount =
                                    label === 'frontend.navbar.menu.favourite-adverts'
                                        ? savedAdsCount
                                        : savedSearchesCount;

                                return (
                                    <li key={label}>
                                        <StyledLinkItem
                                            role="menuitem"
                                            data-testid="nav-user-favourite-menuitem"
                                            aria-label={label}
                                            href={href}
                                            level={0}
                                        >
                                            {t(label)}
                                            {notificationsCount ? (
                                                <NexusUnreadNotifications
                                                    notificationsCount={notificationsCount}
                                                    data-testid="nav-user-favourite-notification"
                                                    aria-label="nav-user-favourite-notification"
                                                >
                                                    {notificationsCount}
                                                </NexusUnreadNotifications>
                                            ) : null}
                                        </StyledLinkItem>
                                    </li>
                                );
                            })}
                        </LinkList>
                    </SubMenuWrapper>
                </>
            ) : (
                <HeaderWrapper onClick={(event): void => handleClick(event)}>
                    <LocalLink href={getNavLinkHref(favouritesLinks[0])} passHref>
                        <MobileLinkWithIcon
                            isLoggedIn={true}
                            aria-label={t('frontend.navbar.menu.users-favourite-adverts')}
                        >
                            <NexusMenuIconWithNotifications
                                shouldHideLabel={false}
                                counter={savedAdsCount + savedSearchesCount}
                                shouldHideChevron
                                isInMainMenu={true}
                            />
                        </MobileLinkWithIcon>
                    </LocalLink>{' '}
                    <NexusChevronRightIcon />
                </HeaderWrapper>
            )}
        </MobileMenuWrapper>
    );
};

export const NavUserFavouriteMobileMenu = memo(NavUserFavouriteMobileMenuBase);
