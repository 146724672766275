import { CurrentUserContext } from '@lib/currentUser';
import { useLazyUser } from '@lib/pages/contexts/LazyUserContext/useLazyUser';
import { useContext, useRef } from 'react';

export const useShouldPauseQueryForLogoutUser = (): boolean => {
    const { isAwaitingUserData, isUserLoggedIn } = useLazyUser();
    const currentUser = useContext(CurrentUserContext); // Protected pages context
    const shouldPauseQueryOnLogoutRef = useRef(true);
    const lastUserStatusRef = useRef<'loggedIn' | 'loggedOut' | undefined>(undefined);

    // Protected pages check
    if (currentUser?.id) {
        shouldPauseQueryOnLogoutRef.current = false;

        return shouldPauseQueryOnLogoutRef.current;
    }
    // Public pages with lazy user context usage
    if (isAwaitingUserData || typeof isUserLoggedIn !== 'boolean') {
        return shouldPauseQueryOnLogoutRef.current;
    }
    // If a user was logged in, and then they are logged out, do not pause the query - let's refresh the gql cache
    shouldPauseQueryOnLogoutRef.current = lastUserStatusRef.current === 'loggedIn' ? false : !isUserLoggedIn;
    lastUserStatusRef.current = isUserLoggedIn ? 'loggedIn' : 'loggedOut';

    return shouldPauseQueryOnLogoutRef.current;
};
