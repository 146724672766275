/* eslint-disable unicorn/filename-case -- treat filename fix separately */
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { theme } from '@lib/styles/themes/nexus';
import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

interface RWD {
    isDesktop: boolean | null;
}

export const NexusRWDContext = createContext<RWD>({ isDesktop: null });

/**
 * This component will help displaying different components for mobile and desktop for nexus breakpoints.
 * The challenge is that:
 * - we cannot rely on JS only, because one type of users will experience layout shifts.
 *   (We don't know user device type during SSR so we must assume one)
 * - we also cannot rely on CSS alone because DOM will contain duplicated content.
 *
 * The solution is to use hybrid approach:
 * 1. Render everything during SSR (mobile and desktop components)
 * 2. Use CSS for media-queries so user will see correct website version
 * 3. Once in browser use JS to unmount duplicated components.
 */
export const NexusRWDContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const { isDesktop: isInitialDesktop } = useContext(RWDContext);
    const [isDesktop, setIsDesktop] = useState<RWD['isDesktop']>(isInitialDesktop);

    useEffect(() => {
        const mqlXl = window.matchMedia(` ${theme.mediaQueries.xlMin}`);

        const onXlBreakpointChange = (event: MediaQueryListEvent): void => {
            setIsDesktop(event.matches);
        };

        if (mqlXl.addEventListener) {
            mqlXl.addEventListener('change', onXlBreakpointChange);
        } else {
            mqlXl.addListener(onXlBreakpointChange);
        }

        setIsDesktop(mqlXl.matches);

        return (): void => {
            // @ts-expect-error Description:
            // Typescript thinks that method 'addEventListener' always exist. Unluckily on Safari 13 it doesnt exist and application is crashing.
            // This is a workaround
            if (mqlXl.addEventListener) {
                mqlXl.removeEventListener('change', onXlBreakpointChange);
            } else {
                mqlXl.removeListener(onXlBreakpointChange);
            }
        };
    }, []);

    const value = useMemo(() => ({ isDesktop }), [isDesktop]);

    return <NexusRWDContext.Provider value={value}>{children}</NexusRWDContext.Provider>;
};
/* eslint-enable unicorn/filename-case */
