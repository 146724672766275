import dynamic from 'next/dynamic';
import type { JSX } from 'react';

import { LanguageSwitcherSpacer } from '../../components/LanguageSwitcher/LanguageSwitcherSpacer';
import { NexusMobileMenuProvider } from '../../contexts/NexusMobileMenuContext';
import { useNavigation } from '../Navigation/hooks/useNavigation';
import { NexusAddItemButton } from '../NexusAddItemButton/NexusAddItemButton';
import { NexusNavUserMenu } from '../NexusNavUserMenu/NexusNavUserMenu';
import {
    NexusDesktopVisibleContainer,
    NexusMenuContainer,
    NexusNavigationContainer,
    NexusNavUserMenuWrapper,
} from './NexusNavigation.theme';

const LazyLanguageSwitcher = dynamic(
    () => import('@widgets/header/MainHeader/components/LanguageSwitcher/LanguageSwitcher'),
    {
        loading: () => <LanguageSwitcherSpacer />,
    },
);

const LazyNexusMenu = dynamic(() => import('@widgets/header/MainHeader/components/Menu/NexusMenu'), {
    ssr: true,
});

interface Props {
    investmentId?: string;
    adUnitOverHeader?: boolean;
}

export const NexusNavigation = ({ investmentId, adUnitOverHeader }: Props): JSX.Element => {
    const { navbar, locales, isVisible, activeLocale, onClickOnAddItemButton } = useNavigation();

    return (
        <NexusNavigationContainer isVisible={navbar ? true : isVisible}>
            {navbar ? (
                <NexusMenuContainer data-testid="header-menu">
                    <NexusMobileMenuProvider>
                        <LazyNexusMenu
                            links={navbar}
                            activeLocale={activeLocale}
                            locales={locales}
                            investmentId={investmentId}
                            adUnitOverHeader={adUnitOverHeader}
                        />
                    </NexusMobileMenuProvider>
                </NexusMenuContainer>
            ) : null}

            <NexusNavUserMenuWrapper>
                <NexusNavUserMenu />
            </NexusNavUserMenuWrapper>
            <NexusDesktopVisibleContainer>
                {locales.length > 1 && (
                    <LazyLanguageSwitcher locales={locales} activeLocale={activeLocale} isMobile={false} />
                )}
            </NexusDesktopVisibleContainer>
            <NexusDesktopVisibleContainer>
                <NexusAddItemButton investmentId={investmentId} onClick={onClickOnAddItemButton} />
            </NexusDesktopVisibleContainer>
        </NexusNavigationContainer>
    );
};
